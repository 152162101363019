var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-div" },
    [
      _c(
        "el-radio",
        {
          attrs: { label: _vm.label },
          on: { change: _vm.change },
          model: {
            value: _vm.type_,
            callback: function ($$v) {
              _vm.type_ = $$v
            },
            expression: "type_",
          },
        },
        [
          _c("span", { staticClass: "cell-symbol" }, [
            _vm._v(_vm._s(_vm.tag_)),
          ]),
          _vm._v(
            " " +
              _vm._s(_vm.$t("common.current")) +
              _vm._s(_vm.targetTimeUnit) +
              _vm._s(_vm.$t("custom.lastTh")) +
              " "
          ),
          _c("el-input-number", {
            attrs: {
              precision: 0,
              min: _vm.lastConfig.min,
              step: _vm.lastConfig.step,
              max: _vm.lastConfig.max,
              size: _vm.size,
            },
            model: {
              value: _vm.lastNum,
              callback: function ($$v) {
                _vm.lastNum = $$v
              },
              expression: "lastNum",
            },
          }),
          _vm._v(" " + _vm._s(_vm.timeUnit) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }