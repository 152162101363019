var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-div" },
    [
      _c(
        "el-radio",
        {
          attrs: { label: _vm.label },
          on: { change: _vm.change },
          model: {
            value: _vm.type_,
            callback: function ($$v) {
              _vm.type_ = $$v
            },
            expression: "type_",
          },
        },
        [
          _c("el-tooltip", { attrs: { effect: "dark", placement: "top" } }, [
            _c("div", { attrs: { slot: "content" }, slot: "content" }, [
              _vm._v(_vm._s(_vm.tag_)),
            ]),
            _c("span", { staticClass: "cell-symbol" }, [_vm._v(",")]),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("common.specified")) + " "),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "collapse-tags": _vm.collapsed,
                size: _vm.size,
                placeholder: _vm.$t("common.placeholderMulti"),
                filterable: "",
                multiple: "",
              },
              model: {
                value: _vm.numArray,
                callback: function ($$v) {
                  _vm.numArray = $$v
                },
                expression: "numArray",
              },
            },
            _vm._l(_vm.nums, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" " + _vm._s(_vm.timeUnit) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }