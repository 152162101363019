var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-div" },
    [
      _c(
        "el-radio",
        {
          attrs: { label: _vm.label },
          on: { change: _vm.change },
          model: {
            value: _vm.type_,
            callback: function ($$v) {
              _vm.type_ = $$v
            },
            expression: "type_",
          },
        },
        [
          _c("span", { staticClass: "cell-symbol" }, [
            _vm._v(_vm._s(_vm.tag_)),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("custom.unspecified")) + " "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }